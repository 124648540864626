<template>
	<div class="fd-editor-view fd-instance" :class="$store.state.mode + '-mode'" :style="editorStyle">

		<preloader-view v-show="this.$store.state.type != 'image'" class="fd-preloader-view"></preloader-view>

		<main-nav-view v-show="this.$store.state.type != 'image'" class="fd-main-nav-view"></main-nav-view>

		<sub-nav-view v-show="this.$store.state.type != 'image'" class="fd-sub-nav-view"></sub-nav-view>

		<div v-show="this.$store.state.type != 'image'" class="fd-renderer-view"
			:style="rendererStyle"
			ref="rendererView"
		>
			<pane-view v-if="$store.state.mode == 'show'" :scale="scale"></pane-view>
			<pane-extra-view v-else></pane-extra-view>
		</div>

		<toolbar-view v-show="this.$store.state.type != 'image'" class="fd-toolbar-view"></toolbar-view>

		<infobar-view v-show="this.$store.state.type != 'image'" class="fd-infobar-view"></infobar-view>

		<editor-extra-view class="fd-editor-extra-view"></editor-extra-view>

		<tutorial-view v-show="this.$store.state.type != 'image'" v-if="tutorial"></tutorial-view>

	</div>
</template>

<script>
import RendererView from './Renderer.vue';
import PreloaderView from './Preloader.vue';
import MainNavView from './MainNav.vue';
import SubNavView from './SubNav.vue';
import PaneExtraView from './PaneExtra.vue';
import ToolbarView from './Toolbar.vue';
import InfobarView from './Infobar.vue';
import EditorExtraView from './EditorExtra.vue';
import TutorialView from './Tutorial.vue';
import CONFIG from '../Config.js';

import {
	_initFullScreen,
	_toggleFullScreen,
	_deepCopy
} from '../util/helper.js';

const mainNavWidth = 46;
const toolbarWidth = 30;

export default {
	name: 'EditorView',
	extends: RendererView,
	components: {
		PreloaderView,
		MainNavView,
		SubNavView,
		PaneExtraView,
		ToolbarView,
		InfobarView,
		EditorExtraView,
		TutorialView
	},
	data() {
		return {
			tutorial: false
		};
	},
	computed: {
		editorStyle() {
			if(this.$store.state.mode == 'show') return {};

			let subNavWidth = this.$store.state.subNav.fixed
				? this.$store.state.subNav.width
				: 0;

			return {
				gridTemplateColumns: `${mainNavWidth}px ${subNavWidth}px 1fr ${toolbarWidth}px`
			}
		},
		rendererStyle() {
			const style = {};

			if(!this.$store.state.settings.showBackground) {
				style.background = '#000';
			}

			return style;
		}
	},
	created() {
		this.$options.resizeObserver = window.ResizeObserver
			? new ResizeObserver(([el]) => {
				this.$store.commit('intern/scale', this.getScale(el.contentRect));
			})
			: {
				observe() {}
			};

		this.$store.commit('setHistory', 'Begin');
	},
	async mounted() {
		if(this.$store.state.type == 'logo') {
			if(this.$store.state.data.background.image == null) {
				this.$store.state.data.background.image = {
					"path": "path"
				}
			}
			this.$store.state.data.background.image.path = CONFIG.BASEPATH + (this.$store.state.screensize.width === 1080 ? '/img/logoposition_vertical.jpg' : '/img/logoposition.jpg');
		}

		_initFullScreen(this.$root.$el, evt => {
			this.$store.commit('fullscreen', evt.isFullScreen);
		});

		document.addEventListener('keydown', evt => {
			if(evt.key == 'F11') {
				this.$root.$emit('fullscreen');
				evt.preventDefault();
			}
		});

		window.addEventListener('beforeunload', e => {
      if(this.$store.state.dirty && this.$store.state.type != 'image') {
        return e.returnValue = 'close';
      }
    });

		this.$root.$on('fullscreen', _ => {
			_toggleFullScreen(this.$root.$el);
		});

		this.$root.$on('historyBack', _ => {
			if(typeof this.$store.state.history !== 'undefined' && this.$store.state.history.length > 0) {
				this.$store.state.setHist = true;
				if(this.$store.state.historyState === null) {
					this.$store.state.historyState = this.$store.state.history.length - 1;
				}
				if(this.$store.state.historyState - 1 >= 0) {
					this.$store.dispatch('subNav/components', null);
					this.$store.state.historyState--;
					this.$store.dispatch('data/replace', _deepCopy(this.$store.state.history[this.$store.state.historyState].content));
				}
				setTimeout(() => {
					this.$store.state.setHist = false;
				}, 100);
			}
		});

		this.$root.$on('historyForward', _ => {
			if(typeof this.$store.state.history !== 'undefined' && this.$store.state.history.length > 0 && this.$store.state.historyState !== null && this.$store.state.historyState + 1 < this.$store.state.history.length) {
				this.$store.state.setHist = true;
				this.$store.dispatch('subNav/components', null);
				this.$store.state.historyState++;
				this.$store.dispatch('data/replace', _deepCopy(this.$store.state.history[this.$store.state.historyState].content));
				setTimeout(() => {
					this.$store.state.setHist = false;
				}, 100);
			}
		});

		this.$root.$on('startTutorial', _ => {
			this.tutorial = true;
		});
		this.$root.$on('stopTutorial', _ => {
			this.tutorial = false;
		});

		await this.$nextTick();

		this.$store.commit('intern/scale', this.getScale({
			width: this.$refs.rendererView.offsetWidth,
			height: this.$refs.rendererView.offsetHeight
		}));

		this.$options.resizeObserver.observe(this.$refs.rendererView);
	}
}
</script>

<style lang="scss">
$main-nav-width: 46px;
$toolbar-width: 30px;
$infobar-height: 22px;

.fd-editor-view {
	display: grid;
	grid-template-columns: $main-nav-width 0px 1fr $toolbar-width;
	grid-template-rows: 1fr $infobar-height;
	grid-template-areas:
		"main-nav sub-nav renderer toolbar"
		"infobar  infobar infobar  infobar";

	&.show-mode {
		grid-template: "renderer" 1fr / 1fr;
	}
}

.fd-preloader-view {
	z-index: 30;
}

.fd-main-nav-view {
	grid-area: main-nav;
	z-index: 20;
}

.fd-sub-nav-view {
	grid-area: sub-nav;
	z-index: 10;
}

.fd-renderer-view {
	grid-area: renderer;
}

.fd-toolbar-view {
	grid-area: toolbar;
}

.fd-infobar-view {
	grid-area: infobar;
}

.fd-editor-extra-view {
	z-index: 40;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-corner,
::-webkit-scrollbar-track {
  background-color: rgba(255,255,255,0.1);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(187, 187, 187, 0.5);
  &:hover {
    background-color: rgb(170, 170, 170);
  }
}
</style>

<i18n lang="de_DE">
fd.language: de_DE
</i18n>
